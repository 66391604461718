import { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Instructor from '../Component/ContentManagement/CourseContent/Instructor/Instructor';
import Overview from '../Component/ContentManagement/CourseContent/Overview/Overview';
import Review from '../Component/ContentManagement/CourseContent/Review/Review';
import { useScrollingManagementContext } from './ScrollingEventManagement';
import { useBranchContext } from './Branchapi';
import { useVideoContext } from './Videoapi';

// Create a context for managing button details
const DetailsButtonContext = createContext();

export function ButtonDetailsProvider({ children }) {
  // State to store button details for multiple components, keyed by component ID
  const [buttonDetailsState, setButtonDetailsState] = useState({});
  const [batchselectedid, setBatchselectedid] = useState('0');
  const [open, setOpen] = useState(false);

  const {branches, loading, error, fetchAllBranchesHandler} = useBranchContext();

  const {videos , fetchAllVideoHandler} = useVideoContext();

  useEffect(() => {
      fetchAllBranchesHandler(); // Fetch branches on component mount
      fetchAllVideoHandler()
    },[]);  
    
  const { ScrollingopenHandler } = useScrollingManagementContext();

    // Navigation Hooks
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
 
     // Handlers to open and close the modal
     const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => setOpen(false);

  // Define a mapping of button names to batch IDs
  const batchIdMap = {
    'All': '0',
    'Old Rajinder Nagar': '1',
    'Mukherjee Nagar': '2',
    'Pune': '3',
    'Hyderabad': '4',
  };

  // Predefined button configurations for different component types
  const buttonLabels = {
    'BranchDetailsButton': [
      { id: 'Our-Course-tab', label: 'Avaiable Courses', target: '#ourcourse', component: <Overview /> },
      { id: 'About-branch-tab', label: 'Branch Details', target: '#aboutbranch', component: <Instructor /> }
    ],

    'CourseDetailsButton': [
      { id: 'Overview-tab', label: 'Overview', target: '#overview', component: <Instructor /> },
      { id: 'syllabus-tab', label: 'Syllabus', target: '#syllabus', component: <Overview /> },
      { id: 'What-You’ll-Learn-tab', label: 'What You’ll Learn', target: 'What-You’ll-Learn', component: <Review /> },
      // { id: 'Demo-Classes-tab', label: 'Demo Classes', target: '#democlasses', component: <Overview /> },
      // { id: 'FAQ', label: 'FAQ', target: 'FAQ', component: <Review /> },
      { id: 'Faculty-tab', label: 'Faculty', target: '#Faculty' , component: <Instructor /> },
      { id: 'Fee Details', label: 'Fee Details', target: '#FacultyMember' , component: <Instructor /> },
      { id: 'BUY NOW', label: 'BUY NOW', target: '#FacultyMember' , component: <Instructor /> }
    ],
    // 'BatchCategory': [
    //   { id: '1', label: 'Old Rajinder Nagar', target: '#1' },
    //   { id: '2', label: 'Mukherjee Nagar', target: '#2' },
    //   { id: '3', label: 'Pune', target: '#3' },
    //   { id: '4', label: 'Hyderabad', target: '#4' },
    // ],

    'BatchCategory': branches.length !==0 ? branches.slice(1) : [
      {
        "branchname": "Old Rajinder Nagar",
        "displayname": "Courses in Delhi - ORN",
        "branchurl": "old-rajinder-nagar",
        "contact": "9811489560",
        "pic": "https://admin.sriramsias.com/upload/profile/241127131604.jpg"
      },
      {
        "branchname": "Mukherjee Nagar",
        "displayname": "Courses in Delhi - MKN",
        "branchurl": "mukherjee-nagar",
        "contact": "8447273027",
        "pic": "https://admin.sriramsias.com/upload/profile/241127131612.jpg"
      },
      {
        "branchname": "Pune",
        "displayname": "Courses in Pune",
        "branchurl": "pune",
        "contact": "9689000979",
        "pic": "https://admin.sriramsias.com/upload/profile/241127131625.jpg"
      },
      {
        "branchname": "Hyderabad",
        "displayname": "Courses in Hyderabad",
        "branchurl": "hyderabad",
        "contact": "8121191985",
        "pic": "https://admin.sriramsias.com/upload/profile/241127131634.jpg"
      }
      ],
    'CourseCategory': branches.length !==0 ? branches : [
      {
        "branchname": "All Branches",
        "branchurl": "All",
        "contact": null,
        "address": null,
        "branchemail": null,
        "pic": null
      },
      {
        "branchname": "Old Rajinder Nagar",
        "displayname": "Courses in Delhi - ORN",
        "branchurl": "old-rajinder-nagar",
        "contact": "9811489560",
        "pic": "https://admin.sriramsias.com/upload/profile/241127131604.jpg"
      },
      {
        "branchname": "Mukherjee Nagar",
        "displayname": "Courses in Delhi - MKN",
        "branchurl": "mukherjee-nagar",
        "contact": "8447273027",
        "pic": "https://admin.sriramsias.com/upload/profile/241127131612.jpg"
      },
      {
        "branchname": "Pune",
        "displayname": "Courses in Pune",
        "branchurl": "pune",
        "contact": "9689000979",
        "pic": "https://admin.sriramsias.com/upload/profile/241127131625.jpg"
      },
      {
        "branchname": "Hyderabad",
        "displayname": "Courses in Hyderabad",
        "branchurl": "hyderabad",
        "contact": "8121191985",
        "pic": "https://admin.sriramsias.com/upload/profile/241127131634.jpg"
      }
      ],
      'VideosCategory': videos.length !==0 ? videos : [
        {
          "branchurl": 31,
          "branchname": "Podcasts"
        },
        {
          "branchurl": 4,
          "branchname": "General Category"
        },
        {
          "branchurl": 8,
          "branchname": "Current Affairs"
        },
        {
          "branchurl": 23,
          "branchname": "Announcement"
        },
        {
          "branchurl": 34,
          "branchname": "Webinar"
        },
        {
          "branchurl": 35,
          "branchname": "Daily News Analysis"
        },
        {
          "branchurl": 39,
          "branchname": "Topper's Mock Interview"
        },
        {
          "branchurl": 40,
          "branchname": "Topper's Talk"
        },
        {
          "branchurl": 41,
          "branchname": "Demo Lecture Video"
        }
      ],
    'sriCourse': [
      { id: 'csat-tab', label: 'CSAT', target: '#csat' },
      { id: 'two-years-gs-tab', label: '2 Years G.S Course', target: '#two-years-gs' },
      { id: 'mains-mastery-tab', label: 'Mains Mastery Course 2025', target: '#mains-mastery' },
      { id: 'one-year-gs-tab', label: '1 Year G.S Course', target: '#one-year-gs' },
      { id: 'optional-subject-tab', label: 'Optional Subject Course', target: '#optional-subject' },
      { id: 'gs-module-tab', label: 'G.S Module Course', target: '#gs-module' },
    ],
  };

  // Function to update button names based on componentName
  const updateButtonDetailsNames = (componentID, componentName ,defaultLabel) => {
    // Get button details for the given componentName
    const buttons = buttonLabels[componentName];
    
    // Handle invalid componentName
    if (!buttons) {
      console.error(`Component name "${componentName}" is not valid or not defined.`);
      return;
    }

    // Determine the default active button. If no defaultLabel is provided, use the first button.
    const defaultActiveLabel = defaultLabel || (buttons.length > 0 ? buttons[0].label : '');
    if(defaultActiveLabel === 'Overview' || defaultActiveLabel === 'Syllabus' || defaultActiveLabel === 'What You’ll Learn' || defaultActiveLabel === 'Faculty' || defaultActiveLabel === 'Fee Details'){
    searchParams.set("tab", defaultActiveLabel.toLowerCase().replace(/ /g, "-"));
        navigate(`${location.pathname}?${searchParams.toString()}`);
    }

    // Update state for the specific component ID
    setButtonDetailsState((prevState) => ({
      ...prevState,
      [componentID]: {
        buttonDetailsNames: buttons,
        activeDetailsButton: defaultActiveLabel, // Set default active button
      },
    }));
  };

  // Function to handle setting the active button
  const handleDetailsButtonClick = (componentID, label) => {
    ScrollingopenHandler();
    if(label !== 'BUY NOW' ){
      if(label === 'Overview' || label === 'Syllabus' || label === 'What You’ll Learn' || label === 'Faculty' || label === 'Fee Details'){
        searchParams.set("tab", label.toLowerCase().replace(/ /g, "-"));
        navigate(`${location.pathname}?${searchParams.toString()}`);
      }
    setButtonDetailsState((prevState) => ({
      ...prevState,
      [componentID]: {
        ...prevState[componentID],
        activeDetailsButton: label,
      },
    }));
}

    // Update batchselectedid based on the label using batchIdMap
    const batchId = batchIdMap[label];
    if (batchId) {
      setBatchselectedid(batchId);
    }

    // if (label !== 'Course Details') {
        // const section1 = document.getElementById("coursedetailsection");
        // const section = document.getElementById("coursebatchsection");
        // if (section1) {
          // section.scrollIntoView({ behavior: "smooth" });
      //     section1.scrollIntoView({ behavior: "smooth" });
      //   }
      // }

      if(label === 'BUY NOW'){
        searchParams.set("tab", label.toLowerCase().replace(/ /g, "-"));
      navigate(`${location.pathname}?${searchParams.toString()}`);
        handleOpen()
      }
  };

  return (
    <DetailsButtonContext.Provider
      value={{
        buttonDetailsState,
        batchselectedid,
        open,
        handleClose,
        updateButtonDetailsNames,
        handleDetailsButtonClick,
      }}
    >
      {children}
    </DetailsButtonContext.Provider>
  );
}

// Hook to use button details context in components
export function useButtonDetailsContext() {
  return useContext(DetailsButtonContext);
}
