import React, {useEffect} from 'react';
import notimg6 from "../../../assets/images/others/map-shape-2.png"

import { Link, useNavigate } from 'react-router-dom';
import UseAnalytics from '../../GoogleAnalytics/UseAnalytics';
import SEO from '../../SeoWidget/SEO/SEO';
import { useQueryContext } from '../../../Context/QueryManagement';

function ThankYouPage() {
    const navigate = useNavigate(); // Initialize navigation
    const { MetaDetails, MetaDetailsHandler, getPagetitle } = useQueryContext(); // Destructure the getPagename, MetaDetails, MetaDetailsHandler, and pagename from the useQueryContext
    

      useEffect(()=>{ 
          window.onload = () => {
              navigate("/"); // Redirect to About page on refresh
            };

          MetaDetailsHandler("thankyou");
          // Use window.onload to detect page reload and redirect to About Page
  
      return () => {
        window.onload = null; // Cleanup on component unmount
      };
       },[])  // Set the MetaDetailsHandler to "Course"

    const metatitle = MetaDetails?.metatitle || "";    // Set the metatitle to the MetaDetails metatitle or an empty string
   const metadescription = MetaDetails?.metadescription || "";    // Set the metadescription to the MetaDetails metadescription or an empty string

       useEffect(() => {
         getPagetitle(metatitle);
       }, [metatitle]);
  return (
    <>
    <UseAnalytics />
    <SEO seot1={metatitle} seot2={metadescription} /> {/* Use the SEO component */}
    <section className="section-gap-equal error-page-area">
            <div className="container">
                <div className="edu-error">
                    <div className="content">
                    <h3 className="title" style={{color:"#CA0000"}}>Thank You, We will call you shortly!!</h3>
                        <h4 className="subtitle">Request Submitted Successfully.</h4>
                        <Link to="/" className="edu-btn text-white"><i className="icon-west text-white"></i>Back to Homepage</Link>
                    </div>
                </div>
            </div>
            <ul className="shape-group">
                <li className="shape-1">
                    <img src={notimg6} alt="Shape"/>
                </li>
            </ul>
        </section>
    
    </>
  )
}

export default ThankYouPage;