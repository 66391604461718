import React from 'react';
import notimg6 from "../../../assets/images/others/map-shape-2.png"
import { Link } from 'react-router-dom';
import UseAnalytics from '../../GoogleAnalytics/UseAnalytics';

function NotFoundPage() {
  return (
    <>
    <UseAnalytics />   
    <section className="section-gap-equal error-page-area">
            <div className="container">
                <div className="edu-error">
                    {/* <div className="thumbnail">
                        <img src={notimg1} alt="404 Error"/>
                        <ul className="shape-group">
                            <li className="shape-1 scene">
                                <img data-depth="2" src={notimg2} alt="Shape"/>

                            </li>
                            <li className="shape-2 scene">
                                <img data-depth="-2" src={notimg3} alt="Shape"/>
                            </li>
                            <li className="shape-3 scene">
                                <img data-depth="2" src={notimg4} alt="Shape"/>
                            </li>
                            <li className="shape-4 scene">
                                <img data-depth="-2" src={notimg5} alt="Shape"/>
                            </li>
                        </ul>
                    </div> */}
                    <div className="content">
                    <h3 className="title" style={{color:"#CA0000"}}>Sorry! Page Not Found</h3>
                        <h4 className="subtitle">Please check the url once</h4>
                        <Link to="/" className="edu-btn text-white"><i className="icon-west text-white"></i>Back to Homepage</Link>
                    </div>
                </div>
            </div>
            <ul className="shape-group">
                <li className="shape-1">
                    <img src={notimg6} alt="Shape"/>
                </li>
            </ul>
        </section>
    
    </>
  )
}

export default NotFoundPage;