import { createContext, useState, useContext } from 'react';
import { fetchExploreation, fetchExploreationByUrl } from '../Config/api';
import { useNotFoundContext } from './NotFound';
import { useStatuscodeContext } from './StatusCode';

const ExplorationContext = createContext();

export function ExplorationProvider({ children }) {
    const [Exploration, setExploration] = useState([]);
    const [ExplorationDetails, setExplorationDetails] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { pagefoundhandler } = useNotFoundContext();  
    const { statuscodehandler } = useStatuscodeContext(); 

        // Fetch Explore data 
        const fetchExplorationHandler = async () => {
          setLoading(true);
          try {
              const data = await fetchExploreation();
              setExploration(data.data)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      }; 

        // Fetch Explore data based on URL 
        const fetchExplorationByUrlHandler = async (exploreurl) => {
          setLoading(true);
          try {
              const data = await fetchExploreationByUrl(exploreurl);
              if(data.statusCode === 200){
              setExplorationDetails(data.data[0])
              }
              pagefoundhandler(data.data.statusCode)
              statuscodehandler(data.data.statusCode)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      }; 
  
    return (
      <ExplorationContext.Provider value={{ Exploration, ExplorationDetails, loading, error, fetchExplorationHandler, fetchExplorationByUrlHandler}}>
        {children}
      </ExplorationContext.Provider>
    );
  }
  
  export function useExplorationContext() {
    return useContext(ExplorationContext);
  }