import { createContext, useState, useContext } from 'react';
import { fetchfooterleft, fetchfooterright } from '../Config/api';

const FooterContext = createContext();

export function FooterProvider({ children }) {
    const [Footerleft, setFooterleft] = useState([]);
    const [Footerright, setFooterright] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
   
      // Fetch all blogs data
      const fetchFooterleftHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchfooterleft();
            setFooterleft(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };

      // Fetch all blogs data
      const fetchFooterrightHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchfooterright();
            setFooterright(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };
  
    return (
      <FooterContext.Provider value={{ Footerleft, Footerright, loading, error, fetchFooterleftHandler, fetchFooterrightHandler}}>
        {children}
      </FooterContext.Provider>
    );
  }
  
  export function useFooterContext() {
    return useContext(FooterContext);
  }