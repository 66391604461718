import { createContext, useState, useContext } from 'react';
import img from "../assets/images/blog/blog-01.png"
// import img from "../../assets/images/logo/Logo-1.png"

const BackgroundContext = createContext();

export function BackgroundProvider({ children }) {
    const [backgroundStyle, setBackgroundStyle] = useState({
        backgroundImage: `linear-gradient(-90deg, #db251fc2, #2d1c70cd), url(${img})`, // Default gradient
        backgroundSize: 'cover', // Ensures background image covers the area
        backgroundPosition: 'center', // Centers the background image
        backgroundRepeat: 'no-repeat', // Prevents repeating of the image
    });

    const [backgroundStyle1, setBackgroundStyle1] = useState({
        backgroundImage: `linear-gradient(-90deg,rgb(93, 4, 1),rgb(23, 2, 105))`, // Default gradient
        backgroundSize: 'cover', // Ensures background image covers the area
        backgroundPosition: 'center', // Centers the background image
        backgroundRepeat: 'no-repeat', // Prevents repeating of the image
    });



    const changeimagehandler = (imgurl) =>{
      setBackgroundStyle((prev) => ({
        ...prev,
        backgroundImage: `linear-gradient(-90deg, #db251fc2, #2d1c70cd), url(${imgurl})`
    }));
    }

    return (
      <BackgroundContext.Provider value={{backgroundStyle, backgroundStyle1, changeimagehandler}}>
            {children}
        </BackgroundContext.Provider>
    );
  }
  
  export function useBackgroundContext() {
    return useContext(BackgroundContext);
  }