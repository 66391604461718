import { Button, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useQueryContext } from '../Context/QueryManagement';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function GetCall() {
  const { getcallHandler } = useQueryContext();
  const navigate = useNavigate();
  const callformRef = useRef(null);

  // Define closeCallForm function outside of useEffect so it can be used in handleForm
  const closeCallFormAfterSubmition = () => {
    const callFormWindow = document.getElementById('callFormWindow');
    callFormWindow.classList.remove('active');
  };

  const handleForm = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    console.log(data);

    const success = await getcallHandler(data.contact);

    if (!success) {
      alert('Please enter a valid mobile number');
    } else {
      navigate('/thank-you');
    }

    if (success) {
      callformRef.current.reset();
      closeCallFormAfterSubmition();  // Call the closeCallForm function here to close the form after successful submission
    }
  };

  useEffect(() => {
    const getCallButton = document.getElementById('getCallButton');
    const callFormWindow = document.getElementById('callFormWindow');
    const closeIcon = document.getElementById('closeIcon');

    const toggleCallForm = () => {
      callFormWindow.classList.toggle('active');
    };

    const closeCallForm = () => {
      callFormWindow.classList.remove('active');
    };

    const handleGlobalClick = (event) => {
      if (
        !callFormWindow.contains(event.target) &&
        !getCallButton.contains(event.target)
      ) {
        closeCallForm();
      }
    };

    getCallButton.addEventListener('click', toggleCallForm);
    closeIcon.addEventListener('click', closeCallForm);
    document.addEventListener('click', handleGlobalClick);

    return () => {
      getCallButton.removeEventListener('click', toggleCallForm);
      closeIcon.removeEventListener('click', closeCallForm);
      document.removeEventListener('click', handleGlobalClick);
    };
  }, []);

  return (
    <>
      <button id="getCallButton">Get a Call</button>
      <div id="callFormWindow">
        <div
          className="bg-danger d-flex justify-content-center align-items-center"
          style={{ width: '20px', height: '20px' }}
          id="closeIcon"
        >
          <span className="text-white fs-4" style={{ paddingTop: '6px' }}>
            &times;
          </span>
        </div>
        <span>
          <Typography
            className="text-center highlight-modal-custom-background text-white px-4 py-4"
            variant="h6"
            component="h2"
          >
            Our Counsellor Will Call You Shortly
          </Typography>
        </span>
        <form
          id="callForm"
          ref={callformRef}
          action=""
          onSubmit={handleForm}
          method="post"
        >
          <input
            type="text"
            id="mobileNumber"
            name="contact"
            placeholder="10 digit mobile Number"
            required
          />
          <Button
            className="mx-auto fs-5 btn-small"
            type="submit"
            variant="contained"
            size="small"
          >
            Schedule a Call
          </Button>
        </form>
      </div>
    </>
  );
}

export default GetCall;
