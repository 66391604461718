import { createContext, useState, useContext } from 'react';
import { fetchAllCourses, fetchTestByUrl } from '../Config/api';

const TestContext = createContext();

export function TestProvider({ children }) {
    const [courses, setCourses] = useState([]);
    const [testDetails, setTestDetails] = useState({});
    const [BatchbyTest, setBatchbyTest] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
   
      // Fetch all branches data
      const fetchAllCoursesHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchAllCourses();
            setCourses(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    }; 

        // Fetch single branch data based on URL 
        const fetchTestByUrlHandler = async (testurl) => {
          setLoading(true);
          try {
              const data = await fetchTestByUrl(testurl);
              setTestDetails(data.data.course)
              setBatchbyTest(data.data.batches)
              console.log(data)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      };
  
    return (
      <TestContext.Provider value={{ courses, testDetails, BatchbyTest, loading, error, fetchAllCoursesHandler, fetchTestByUrlHandler}}>
        {children}
      </TestContext.Provider>
    );
  }
  
  export function useTestContext() {
    return useContext(TestContext);
  }