// src/hooks/useEduJs.js
import { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
// import '../../node_modules/swiper/swiper-bundle.min.css'
// import '../../public/assets/css/vendor/swiper-bunble.min.css';

// import Swiper from 'swiper';
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
import Parallax from 'parallax-js';
import Odometer from 'odometer';
import 'odometer/themes/odometer-theme-default.css';
// import styles bundle
import 'swiper/css/bundle';
import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';
import sal from 'sal.js'; // Import sal.js
import 'sal.js/dist/sal.css'; // Import sal.css for animations
import Vivus from 'vivus';
import SVGInject from '@iconfu/svg-inject';

// import Masonry from 'react-masonry-css';
// import './MasonryGrid.css';

// window.jQuery = window.$ = $

const useEduJs = () => {
  const bgMarqueRef = useRef(null);
  const stickyPlaceholderRef = useRef(null); // Reference for the sticky placeholder
  const menuRef = useRef(null); // Reference for the menu
  const odometerRefs = useRef([]); // Reference for the menu
  const observerRef = useRef(null); // Ref to hold IntersectionObserver instance

  useEffect(() => {
    const bgMarque = () => {
      let positionX = 0;
      const speed = 1;

      const updatePosition = () => {
        positionX += speed;
        if (bgMarqueRef.current) {
          bgMarqueRef.current.style.backgroundPositionX = `${-positionX}px`;
        }
        requestAnimationFrame(updatePosition);
      };

      requestAnimationFrame(updatePosition);
    };

    const stickyHeaderMenu = () => {
      const stickyPlaceHolder = $(stickyPlaceholderRef.current);
      const menu = $(menuRef.current);

      const onScroll = () => {
        const menuH = menu.outerHeight();
        const topHeaderH = $('.header-top-bar').outerHeight() || 0;
        const targetScroll = topHeaderH + 200;

        if ($(window).scrollTop() > targetScroll) {
          menu.addClass('edu-sticky');
          stickyPlaceHolder.height(menuH); // Set the height of the placeholder
        } else {
          menu.removeClass('edu-sticky');
          stickyPlaceHolder.height(0); // Reset the height of the placeholder
        }
      };

      $(window).on('scroll', onScroll);

      // Clean up event listener on unmount
      return () => {
        $(window).off('scroll', onScroll);
      };
    };

    const mouseMoveAnimation = () => {
      $(".scene").each(function () {
        new Parallax($(this)[0]);
      });
    };


    // Mobile Menu Popup Functionality
    const popupMobileMenu = () => {
      $(".hamberger-button").on("click", () => $(".popup-mobile-menu").addClass("active"));
      $(".close-menu").on("click", () => {
        $(".popup-mobile-menu").removeClass("active");
        $(".popup-mobile-menu .mainmenu .has-droupdown > Link")
          .siblings(".submenu")
          .removeClass("active")
          .slideUp("400");
        $(".popup-mobile-menu .mainmenu .has-droupdown > Link").removeClass("open");
      });
      $(".responsive-close-menu-item").on("click", () => {
        $(".popup-mobile-menu").removeClass("active");
        $(".popup-mobile-menu .mainmenu .has-droupdown > Link")
          .siblings(".submenu")
          .removeClass("active")
          .slideUp("400");
        $(".popup-mobile-menu .mainmenu .has-droupdown > Link").removeClass("open");
      });
      $(".popup-mobile-menu .mainmenu .has-droupdown > Link").on("click", (e) => {
        e.preventDefault();
        $(e.currentTarget)
          .siblings(".submenu")
          .toggleClass("active")
          .slideToggle("400");
        $(e.currentTarget).toggleClass("open");
      });
      $(".popup-mobile-menu, .splash-mobile-menu .mainmenu li Link").on("click", (e) => {
        if (e.target === e.currentTarget) {
          $(".popup-mobile-menu").removeClass("active");
          $(".popup-mobile-menu .mainmenu .has-droupdown > Link")
            .siblings(".submenu, .mega-menu")
            .removeClass("active")
            .slideUp("400");
          $(".popup-mobile-menu .mainmenu .has-droupdown > Link").removeClass("open");
        }
      });
    };

        // Search Popup Functionality
        const searchPopup = () => {
          $(".search-trigger").on("click", () => {
            $(".edu-search-popup").addClass("open");
          });
          $(".close-trigger, .edu-search-popup").on("click", () => {
            $(".edu-search-popup").removeClass("open");
          });
          $(".edu-search-popup .edublink-search-popup-field").on("click", (e) => {
            e.stopPropagation();
          });
        };

        const initOdometer = (el) => {
          const odometer = new Odometer({
            el: el,
            value: 0,
            format: '(,ddd)',
          });
          odometer.render();
          setTimeout(() => {
            odometer.update(el.getAttribute("data-odometer-final"));
          }, 300);
        };
    
        const counterUp = () => {
          observerRef.current = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const counterElement = entry.target;
                if (!counterElement.classList.contains("counted")) {
                  counterElement.classList.add("counted");
                  const odometerElements = counterElement.querySelectorAll(".odometer");
                  odometerElements.forEach((el) => initOdometer(el));
                  observer.unobserve(counterElement);
                }
              }
            });
          }, { threshold: 0.5 });
    
          odometerRefs.current.forEach((ref) => {
            if (ref) observerRef.current.observe(ref);
          });
        };
              

       // Swiper Slider
    const swiperSlider = () => {
      new Swiper(".home-one-testimonial-activator", {
        slidesPerView: 1,
        spaceBetween: 30,
        spaceBetween: 0,
        loop: true,
        pagination: false,
        grabCursor: true,
        speed: 1500,
        autoplay: { delay: 3500 },
        breakpoints: {
          577: { slidesPerView: 2 },
        },
      });
    };

      const lightboxActivation = () => {
        $('.lightbox').magnificPopup({
          type: 'image',
          gallery: {
            enabled: true,
          },
        });
      };
  

      const radialProgress = () => {
        $('.radial-progress').each((_, el) => {
          const value = $(el).data('progress') || 50;
          $(el).css({
            'background-image': `conic-gradient(#4e73df ${value}%, #f3f4f5 ${value}%)`,
          });
        });
      };

      const sitePreloader = () => {
        $(window).on('load', () => {
          $('.preloader').fadeOut(500);
        });
      };
  
      // Scroll Animation on Load (sal.js)
    const salActive = () => {
      sal({
        threshold: 0.01,
        once: true,
      });
    };

        // Vivus SVG Animation
        const svgVivusAnimation = () => {
          SVGInject(document.querySelectorAll('img.svgInject'), {
            makeIdsUnique: true,
            afterInject: (_, svg) => {
              new Vivus(svg, {
                duration: 80,
                type: 'delayed', // You can change the type to 'sync', 'oneByOne', etc.
                start: 'inViewport', // Animation starts when the SVG is in the viewport
              });

              const vivusInstance = new Vivus(svg, {
                duration: 80,
                type: 'delayed',
                start: 'manual', // Set to manual to start only on hover
              });

                                // Add hover event listeners to start the animation
                                svg.addEventListener('mouseenter', () => {
                                  vivusInstance.reset().play(); // Reset and play animation on hover
                                });
            },
          });
        };

  

    bgMarque();
    stickyHeaderMenu();
    popupMobileMenu();
    // magnigyPopup();
    swiperSlider();
    // lightboxActivation();
    radialProgress();
    sitePreloader();
    salActive();

    // Initialize svgVivusAnimation on component mount
    svgVivusAnimation();

    searchPopup();
    mouseMoveAnimation();

    counterUp();




    // Clean up event listeners on unmount
    return () => {
      $(window).off('scroll');
      $('.menu-btn').off('click');
      $(".search-trigger, .close-trigger, .edu-search-popup").off('click');
      $(".edu-search-popup .edublink-search-popup-field").off('click');
      observerRef.current?.disconnect();
    };
  }, []);

  return { bgMarqueRef, stickyPlaceholderRef, menuRef, odometerRefs };
};

export default useEduJs;
