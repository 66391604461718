import { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

// Tracking function
const sendTrackingData = (eventType, referUrl, pageUrl, pageTitle, x = null, y = null, target = null) => {
  const trackingImg = new Image(1, 1);
  trackingImg.style.display = 'none';
  const trackingName = Cookies.get('trackname') || '';
  const trackingContact = Cookies.get('trackcontact') || '';
  const trackingEmail = Cookies.get('trackemail') || '';
  console.log(trackingName, trackingContact, trackingEmail);

  trackingImg.src = `https://www.sriramsias.com/tr.php?trackname=${encodeURIComponent(trackingName)}&trackcontact=${encodeURIComponent(trackingContact)}&trackemail=${encodeURIComponent(trackingEmail)}&events=${encodeURIComponent(eventType)}&refer_url=${encodeURIComponent(
    referUrl
  )}&page_url=${encodeURIComponent(pageUrl)}&page_title=${encodeURIComponent(pageTitle)}${
    x !== null ? `&x=${x}` : ''
  }${y !== null ? `&y=${y}` : ''}${target !== null ? `&target=${target}` : ''}`;
  document.body.appendChild(trackingImg);
};

// Context Creation
const TrackingManagementContext = createContext(); 

export function TrackingManagementProvider({ children }) {
  const [trackingData, setTrackingData] = useState(null);
  // id="track_button" data-button-id="Click on Delhi Brochure Link"
  const [trackingDataValue, setTrackingDataValue] = useState("Click on tracking button here");
  const trackingid = "track_button";
  
  const handleButtonClick = (event) => {
    // const buttonElement = document.getElementById(trackingid);
    // if (!document.getElementById(trackingid)) {
    //   console.log("Button not found");
    //   return;
    // }
  //  console.log("working tracking")

  //  const buttonText = buttonElement.innerText || buttonElement.textContent.trim();
  //  setTrackingDataValue(buttonText); // Directly update the value using data-button-id
  //  alert(`Tracking Value: ${buttonText}`);

    // const buttonElement = document.getAttribute('data-button-id');
    // const buttonText = buttonElement.textContent.trim();

    // const { clientX: x, clientY: y } = event;
    // const target = event.target.getAttribute('data-button-id') || event.target.tagName;
    // sendTrackingData('click', document.referrer, window.location.href, document.title, x, y, target);
    // setTrackingData({ x, y, target });
    
          // Update the tracking data value
          // if (target) {
          //   setTrackingDataValue(`Clicked on: ${target}`);
          // }
    


    // setTrackingDataValue(buttonText);

    // alert("working tracking1");
  };

  const handleUrlChange = () => {
    sendTrackingData('page_view', document.referrer, window.location.href, document.title);
  };


  useEffect(() => {
    const handleClick = (event) => {
      const target = event.target.closest('[data-button-id]');
      if (target) {
        const { clientX: x, clientY: y } = event;
        const targetId = target.getAttribute('data-button-id');
        sendTrackingData('click', document.referrer, window.location.href, document.title, x, y, targetId);
        setTrackingData({ x, y, target: targetId });
      }
    };

  
    document.body.addEventListener('click', handleClick);
  
    return () =>{

     document.body.removeEventListener('click', handleClick);
    }
  }, []);
  

  return (
    <TrackingManagementContext.Provider value={{ trackingData, trackingDataValue, trackingid, handleButtonClick, handleUrlChange }}>
      {children}
    </TrackingManagementContext.Provider>
  );
}

export function useTrackingManagementContext() {
  return useContext(TrackingManagementContext);
}
