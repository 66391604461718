import React , {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useToggleContext } from '../../../Context/Togglemanagement';
import { useScrollingManagementContext } from '../../../Context/ScrollingEventManagement';
import { useValidateUrlContext } from '../../../Context/Validateurl';
import $ from 'jquery'; 

function SingleNabar3({submenu, title}) {
   const {isVisible, mobilemenuid, TogglecloseHandler, menuidhandler} = useToggleContext();
   const { ScrollingopenHandler } = useScrollingManagementContext();
   const { validateURL } = useValidateUrlContext();

   useEffect(()=>{
    $(".responsive-close-menu-item").on("click", () => {
      $(".popup-mobile-menu").removeClass("active");
      $(".popup-mobile-menu .mainmenu .has-droupdown > Link")
        .siblings(".submenu")
        .removeClass("active")
        .slideUp("400");
      $(".popup-mobile-menu .mainmenu .has-droupdown > Link").removeClass("open");
      menuidhandler(null);
    });
   },[])

  return (
    <>
     <ul className={`submenu custom-single-modile-drop ${isVisible !== "hide" ? '' : 'hiddenmobile-submenu'} ${mobilemenuid === title ? 'hiddenmobile-submenu active' : ''}`} onClick={()=>ScrollingopenHandler()}> 
    {submenu.map((course,index)=>(
     <li key={index}><Link className='responsive-close-menu-item' to={validateURL(course.linkurl) ? `${course.linkurl}` :`/${course.linkurl}`}>{course.title}</Link></li>
    ))}    
    </ul>
    </>
  )
}

export default SingleNabar3;