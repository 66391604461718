import { createContext, useState, useContext } from 'react';
import {fetchcurrentaffairs, fetchMorecurrentaffairs} from '../Config/api';
import { useNotFoundContext } from './NotFound';
import { useStatuscodeContext } from './StatusCode';

const CurrentaffairsContext = createContext();

export function CurrentaffairsProvider({ children }) {
    const [currentaffairs, setCurrentAffairs] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { pagefoundhandler } = useNotFoundContext();  
    const { statuscodehandler } = useStatuscodeContext();  
   
      // Fetch all blogs data
      const fetchAllCurrentAffairsHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchcurrentaffairs();
            setCurrentAffairs(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    }; 

    // Fetch More videos data
    const fetchMoreCurrentAffairsHandler = async (limit) => {
      setLoading(true);
      try {
          const data = await fetchMorecurrentaffairs(limit);
          setCurrentAffairs((prev) => [...prev, ...data.data]); // Set branches data from response
          setError(null);
      } catch (err) {
          console.error("Error fetching branches:", err);
          setError("Failed to load branches");
      } finally {
          setLoading(false);
      }
  };

    return (
      <CurrentaffairsContext.Provider value={{currentaffairs, loading, error, fetchAllCurrentAffairsHandler, fetchMoreCurrentAffairsHandler}}>
        {children}
      </CurrentaffairsContext.Provider>
    );
  }
  
  export function useCurrentaffairsContext() {
    return useContext(CurrentaffairsContext);
  }