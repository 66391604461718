import React, { useState } from 'react';
import { useBranchContext } from '../../../Context/Branchapi';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { useToggleContext } from '../../../Context/Togglemanagement';

function MobileMenu() { 
         const { branches, coursebybranch, fetchAllBranchesHandler, fetchBranchByUrlHandler } =
         useBranchContext();

  const [isOpen, setIsOpen] = useState(false);
  const [activeBranchUrl, setActiveBranchUrl] = useState(null); // Track the active branch URL
  const [activeCourseUrl, setActiveCourseUrl] = useState(null); // Track the active branch URL

      const {mobilemenuid, isVisible, ToggleopenHandler, isMobileVisible, TogglecloseHandler, menuidhandler, TogglemobileopenHandler, TogglemobilecloseHandler} = useToggleContext();

  useEffect(()=>{
    fetchAllBranchesHandler()
    

 },[])

 useEffect(() => {
    if (isVisible) {
      setActiveBranchUrl(null); // Reset the active branch URL when the dropdown closes
    }
  }, [isVisible]);

  // const toggleDropdown1 = () => {
  //   setIsOpen(!isOpen); 
  // };
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
 
    // fetchBranchByUrlHandler(branch)
    
  };

  const handledropdown = (branchUrl) => {

    fetchBranchByUrlHandler(branchUrl)
    setActiveBranchUrl(branchUrl); // Set the active branch URL when a branch is selected
    // TogglecloseHandler()
} 

const handlecourseactive =(courseurl)=>{
  setActiveCourseUrl(courseurl)
}




useEffect(() => {
  $(".reponsive-link").on("click", () => {
    $(".dropdown").addClass("dropdown-menu-after-click"); 
  });
}, []);

  return (
<> 
      {/* {!isOpen && ( */}
        <ul className={`${isVisible === 'hide' ? 'dropdown-menu1 dropdown-menu-after-click responsive-mobile-custom-multilevel-dropdown custom-single-drop' : 'responsive-mobile-custom-multilevel-dropdown dropdown-menu1 custom-single-drop'}`}>
         {branches.slice(1).map((branch, index) =>( <li>
            <div className="sub-dropdown p-0 m" onMouseOver={()=>handledropdown(branch.branchurl)}>

              <div className="custom-mobile-paste-button">
  <button className="custom-mobile-paste-container-button d-flex justify-content-between align-items-center w-100" onClick={()=> toggleDropdown()} onMouseLeave={()=>TogglemobilecloseHandler()}>{branch.displayname} &nbsp; <i className='icon-31' style={{fontSize:'8px'}}></i></button>
 {isMobileVisible !== '' && <div className="custom-mobile-paste-container-dropdown-content">
    {
                                                            coursebybranch.map((course)=>(
                                                                <li style={{whiteSpace:'nowrap'}} ><Link to={`/course-details/${course.courseurl}`} className={`px-3 ${activeCourseUrl === course.courseurl ? 'sub-dropdown-toggle-active-course' : 'sub-dropdown-toggle-course'}`} onMouseOver={()=>handlecourseactive(course.courseurl)} onClick={() => TogglecloseHandler()} style={{whiteSpace:'nowrap'}}>{course.coursename}</Link></li>
                                                            ))
                                                        }
  </div>}
</div>
            </div>
          </li>))}
        </ul>
      {/* )} */}
      </>

  );
}

export default MobileMenu;