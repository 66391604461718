import { createContext, useState, useContext } from 'react';
import { fetchMorevideocategory, fetchVideocategory, fetchVideocategoryByUrl } from '../Config/api';
import { useNotFoundContext } from './NotFound';
import { useStatuscodeContext } from './StatusCode';

const VideoContext = createContext();

export function VideoProvider({ children }) {
    const [videos, setVideos] = useState([]);
    const [videoDetails, setVideoDetails] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { pagefoundhandler } = useNotFoundContext();  
    const { statuscodehandler } = useStatuscodeContext(); 
   
      // Fetch all videos data
      const fetchAllVideoHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchVideocategory();
            setVideos(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };

        // Fetch single video data based on URL 
        const fetchVideoByUrlHandler = async (id) => {
          setLoading(true);
          try {
              const data = await fetchVideocategoryByUrl(id);
              if(data.statusCode === 200){
                  setVideoDetails(data.data)
              }
              pagefoundhandler(data.data.statusCode)
              statuscodehandler(data.data.statusCode)
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      }; 

      // Fetch More videos data
      const fetchMoreVideoHandler = async (id,limit) => {
        setLoading(true);
        try {
            const data = await fetchMorevideocategory(id, limit);
            setVideoDetails((prev) => [...prev, ...data.data]); // Set branches data from response
            pagefoundhandler(data.data.statusCode)
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };
  
    return (
      <VideoContext.Provider value={{ videos, videoDetails, loading, error, fetchAllVideoHandler, fetchVideoByUrlHandler, fetchMoreVideoHandler}}>
        {children}
      </VideoContext.Provider>
    );
  }
  
  export function useVideoContext() {
    return useContext(VideoContext);
  }